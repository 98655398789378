import { Button, Form, Input, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { DatePicker } from 'components/common/picker'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'
import { useAppConfigStore } from '../../../../stores/global'

const { Option } = Select
const { RangePicker } = DatePicker
type RangeValue = [Dayjs | null, Dayjs | null] | null

interface IProps {
  onSubmit: (values) => void
  loading: boolean
  initialValues?: any
}

const OrderSearch = ({ loading, onSubmit, initialValues = {} }: IProps) => {
  const { appConfig } = useAppConfigStore()
  const [form] = Form.useForm()
  const onFinish = (values) => {
    let startDate, endDate
    if (values.dateRange?.length) {
      ;(startDate = dayjs(values.dateRange[0]).format('YYYY-MM-DDTHH:mm:ssZ')),
        (endDate = dayjs(values.dateRange[1]).format('YYYY-MM-DDTHH:mm:ssZ'))
    }
    const objSearch = {
      keyword: values.keyword,
      status: values.status,
      productCatalogType: values.productCatalogType,
      startDate,
      endDate,
    }
    onSubmit(objSearch)
  }
  const onFinishFailed = (error) => {
    console.error(error)
  }

  const [dates, setDates] = useState<RangeValue>(null)
  const [value, setValue] = useState<RangeValue>(null)

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false
    }
    const tooLate = dates[0] && current.diff(dates[0], 'year') > 1
    const tooEarly = dates[1] && dates[1].diff(current, 'year') > 1
    return !!tooEarly || !!tooLate
  }

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null])
    } else {
      setDates(null)
    }
  }

  const onClear = (value: any, field: string) => {
    if (!value) {
      form.setFieldValue(field, undefined)
      form.submit()
    }
  }

  return (
    <div>
      <Form
        name='search-order'
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete='off'
        className='flex gap-2'
      >
        <Form.Item name='keyword' className='w-80'>
          <Input
            size='large'
            suffix={<SearchOutlined className='text-black text-opacity-25' />}
            placeholder='Order no./Corp. name or number'
            allowClear
            onChange={(event) => onClear(event.target.value, 'keyword')}
          />
        </Form.Item>

        <Form.Item name='status'>
          <Select
            placeholder='Status'
            size='large'
            style={{ width: 240 }}
            allowClear
            onChange={(value) => onClear(value, 'status')}
          >
            {appConfig.orderStatus?.map((order: any) => (
              <Option value={order.key} key={order.key}>
                {order.value}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name='productCatalogType'>
          <Select
            placeholder='Giftcard type'
            size='large'
            allowClear
            onChange={(value) => onClear(value, 'productCatalogType')}
          >
            <Option value='GIFT_CARD'>Giftcard</Option>
            <Option value='E_COUPON'>E-Coupon</Option>
          </Select>
        </Form.Item>

        <Form.Item name='dateRange'>
          <RangePicker
            size='large'
            value={dates || value}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(value) => {
              setValue(value)
              onClear(value, 'dateRange')
            }}
            onOpenChange={onOpenChange}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='default'
            size='large'
            htmlType='submit'
            loading={loading}
            className='btn-submit'
          >
            Search
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default OrderSearch
