import { AUTHEN } from 'constants/storageKeys'
import axios, { AxiosResponse, AxiosError, Method } from 'axios'
import { TOKEN_FAILED } from 'constants/common'

const fetch = (
  method: Method,
  endpoint = '/',
  data = {},
  headers = {},
  options: any = {},
  isRequiredOnlyData: boolean = true,
  apiUrl = process.env.REACT_APP_API_URL,
) => {
  const url = `${apiUrl}${endpoint}`
  const queryName = method === 'GET' ? 'params' : 'data'
  const api = axios.create({
    baseURL: url,
  })

  api.interceptors.request.use((config: any) => {
    const accessToken = sessionStorage.getItem(AUTHEN.ACCESS_TOKEN)
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    config.headers['Key'] = process.env.REACT_APP_API_KEY
    return config
  })

  return api
    .request({
      method,
      url,
      [queryName]: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
        ...headers,
      },
      ...options,
    })
    .then((response: AxiosResponse) => {
      return isRequiredOnlyData ? response.data : response
    })
    .catch((error: AxiosError) => {
      if (error.response) {
        const errorResponse: any = error.response
        if (
          errorResponse.status === 401 &&
          TOKEN_FAILED.includes(errorResponse.data?.error?.type)
        ) {
          sessionStorage.clear()
          window.location.href = '/?error=invalid_token'
        }
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // return Promise.reject(error.response.data)
        return Promise.reject(error.response.data)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error('error.request', error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject({
          error: {
            description: error.message,
          },
        })
      }
    })
}

export default fetch
