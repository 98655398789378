import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useAuth } from '../../stores/authorized'
import { rediectOneLogin, staffLogin } from 'services/auth.service'
import { AUTHEN } from 'constants/storageKeys'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import Popup from 'components/common/popup/Popup'
import { useNavigate } from 'react-router-dom'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
interface AccessTokenPayload extends JwtPayload {
  name: string
  username: string
  email: string
  userType: string
  role: string
  employeeId: string
}

const SignInPage = () => {
  const navigate = useNavigate()
  const { setAuth } = useAuth()
  const [loading, setLoading] = useState<boolean>(false)
  const [isLoginFail, setIsLoginFail] = useState<boolean>(false)
  const [isInvalidToken, setIsInvalidToken] = useState<boolean>(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const codeResponse = params.get('code')
    if (codeResponse) {
      callStaffLogin(codeResponse)
    }

    const errorInvalidResponse = params.get('error')
    if (errorInvalidResponse === 'invalid_token') {
      setIsInvalidToken(true)
    }
  }, [window.location.search])

  const handlePopupOk = () => {
    setIsLoginFail(false)
  }

  const callStaffLogin = async (codeResponse) => {
    try {
      const body = {
        code: codeResponse,
      }
      const payload = await staffLogin(body)
      if (payload?.status?.code === 200 && payload?.data && payload?.data?.accessToken) {
        const decoded = jwtDecode<AccessTokenPayload>(payload.data.accessToken)
        setAuth({
          user: {
            name: decoded.name,
            username: decoded.username,
            email: decoded.email,
            userType: decoded.userType,
            role: decoded.role,
            employeeId: decoded.employeeId,
          },
          isLoggedIn: true,
          authorized: {
            accessToken: payload?.data.accessToken,
            refreshToken: payload?.data.refreshToken,
            expiresIn: payload?.data.expiresIn,
            tokenType: payload?.data.tokenType,
          },
        })
        sessionStorage.setItem(AUTHEN.ACCESS_TOKEN, payload?.data.accessToken)
        sessionStorage.setItem(AUTHEN.REFRESH_TOKEN, payload?.data.refreshToken || '')
        window.location.href = '/orders'
      }
    } catch (err: any) {
      setIsLoginFail(true)
    }
  }

  const signIn = async () => {
    setLoading(true)
    if (process.env.REACT_APP_DEPLOY_ENV !== 'local') {
      const authorizationEndpointUrl = rediectOneLogin()
      window.location.href = authorizationEndpointUrl
    } else {
      navigate('/?code=' + process.env.REACT_APP_MOCK_CODE)
    }
  }

  const handlePopupInvalidTokenOk = () => {
    setIsInvalidToken(false)
  }

  return (
    <>
      <div className='flex py-16 bg-gradient-to-br from-sky-50 to-gray-200 min-h-screen'>
        <div className='relative container m-auto px-6 text-gray-500 md:px-12 xl:px-40'>
          <div className='m-auto md:w-8/12 lg:w-6/12 xl:w-6/12'>
            <Spin spinning={loading} indicator={antIcon}>
              <div className='rounded-xl bg-white shadow-xl'>
                <div className='p-6 pt-0 sm:p-16 '>
                  <div className='space-y-4 p-5 rounded-lg'>
                    <img
                      src='https://cdn.onelogin.com/images/brands/logos/login/944e594d94bba5b81563318c3753f491477ed1bf.png?1622217552'
                      loading='lazy'
                      className='w-30 m-auto'
                      alt='tailus logo'
                    />
                  </div>
                  <div className='mt-16 grid space-y-4'>
                    <button
                      className='group h-12 px-6 border-2 border-transparent bg-teal-400 hover:bg-white rounded-full transition duration-300 
                                     hover:border-teal-300 focus:bg-teal-100 active:bg-teal-100'
                      onClick={signIn}
                    >
                      <div className='relative flex items-center space-x-4 justify-center'>
                        <span className='block w-max font-semibold tracking-wide text-white text-sm transition duration-300 group-hover:text-teal-400 sm:text-base'>
                          Continue with Lotus&lsquo;s OneLogin
                        </span>
                      </div>
                    </button>
                  </div>

                  <div className='mt-24 space-y-4 text-gray-600 text-center sm:-mb-8'>
                    <p className='text-xs'>
                      By proceeding, you agree to our <br />
                      <span className='underline '>Terms of Use</span> and confirm you have read our{' '}
                      <span className='underline'>Privacy and Cookie Statement</span>.
                    </p>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
        <Popup
          title='ล็อกอินไม่สำเร็จ'
          message='กรุณาทำรายการใหม่อีกครั้ง'
          isOpen={isLoginFail}
          onOk={handlePopupOk}
          status='error'
          width={400}
        />

        <Popup
          title='Session Expired'
          message='การ Login ของคุณหมดอายุ กรุณา Login เข้าสู่ระบบใหม่อีกครั้ง'
          isOpen={isInvalidToken}
          onOk={handlePopupInvalidTokenOk}
          status='error'
          width={400}
        />
      </div>
    </>
  )
}

export default SignInPage
