import { useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { getAppConfig } from 'services/appConfig.service'
import { useAppConfigStore } from 'stores/global'
import { ROLE_ACCESS } from 'constants/accessRole'

// Pages
import PrivateRoute from './components/PrivateRoute'
import NotFound from './pages/errors/NotFound'
import SignInPage from './pages/signin'
import OrderPage from 'pages/order/list'
import CreateOrderPage from 'pages/order/create'
import ReportPage from 'pages/report/Report'
import DetailOrderPage from 'pages/order/detail/DetailOrder'
import { useAuth } from 'stores/authorized'

const App = () => {
  const { appConfig, setAppConfig } = useAppConfigStore()
  const { auth } = useAuth()

  useEffect(() => {
    const fetchAppConfig = async () => {
      const payload = await getAppConfig().catch((err) => console.log(err))
      if (payload?.status?.code === 200) {
        setAppConfig(payload.data)
      }
    }
    const isConfigInvalid = !appConfig || !Object.keys(appConfig)?.length
    if (auth?.isLoggedIn && isConfigInvalid) {
      fetchAppConfig()
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/orders' element={<PrivateRoute component={OrderPage} />} />
        <Route path='order/create' element={<PrivateRoute component={CreateOrderPage} />} />
        <Route path='order/:orderId' element={<PrivateRoute component={DetailOrderPage} />} />
        <Route
          path='/reports'
          element={<PrivateRoute component={ReportPage} roles={ROLE_ACCESS.REPORT} />}
        />

        <Route index element={<SignInPage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
