import { useNavigate } from 'react-router-dom'
import { Layout, Menu, Modal, MenuProps, Badge } from 'antd'
import { ExclamationCircleOutlined, BellOutlined } from '@ant-design/icons'
import { useAuth } from '../../../stores/authorized'

const { confirm } = Modal
const Navbar = () => {
  const navigate = useNavigate()
  const { auth, setAuth } = useAuth()

  const signOut = () => {
    confirm({
      title: 'ต้องการออกจากระบบ',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการล๊อกออกจากระบบ',
      centered: true,
      onOk() {
        setAuth(undefined)
        sessionStorage.clear()
        navigate('/signin')
      },
    })
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <Badge count={5} style={{ backgroundColor: '#FFE500', color: '#000000' }}>
          <BellOutlined style={{ fontSize: 18 }} />
        </Badge>
      ),
      key: 'notification',
    },
    {
      label: (
        <div className='py-2 '>
          <div className='relative top-1 h-10 border-r boder-r-solid border-gray-200'></div>
        </div>
      ),
      key: 'divider',
      className: 'menu-divider',
    },
    {
      label: auth?.user.name,
      key: 'infomenu',
      children: [
        {
          key: 'infomation',
          label: <span>ข้อมูลส่วนตัว</span>,
        },
        {
          key: 'password',
          label: <span>เปลี่ยนรหัสผ่าน</span>,
        },
        {
          key: 'signout',
          label: <span onClick={signOut}>ออกจากระบบ</span>,
        },
      ],
    },
  ]

  return (
    <Layout.Header className='site-layout-background py-0 px-4'>
      <div className='float-left flex flex-col items-center h-full py-2 '>
        <div className='leading-8 mt-1 ml-[10px]'>
          <img src='/assets/images/logo.svg' loading='lazy' alt='logo' className='w-20' />
          <div className='text-gray-400'>B2B Giftcard</div>
        </div>
      </div>

      <div className='flex justify-end'>
        <div className='list-unstyled list-inline'>
          <Menu
            items={items}
            mode='horizontal'
            defaultSelectedKeys={['infomenu']}
            disabledOverflow={true}
          />
        </div>
      </div>
    </Layout.Header>
  )
}

export default Navbar
